import { createApp, h } from 'vue'
import { createInertiaApp } from '@inertiajs/vue3'
import { createPinia } from 'pinia'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import route from 'ziggy-js'
import * as Sentry from '@sentry/vue'
import {} from './bootstrap'
import isToday from 'dayjs/plugin/isToday'
import isYesterday from 'dayjs/plugin/isYesterday'
import dayjs from 'dayjs'
import { createI18n } from './i18n'

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'GetStaff'

dayjs.extend(isToday)
dayjs.extend(isYesterday)

createInertiaApp({
  title: (title) => `${title} - ${appName}`,
  // eslint-disable-next-line global-require,import/no-dynamic-require
  resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
  setup({
    el, App, props, plugin,
  }) {
    const app = createApp({ render: () => h(App, props), createPinia, createI18n })

    Sentry.init({
      app,
      dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
      environment: import.meta.env.APP_ENV,
    })

    app
      .use(plugin)
      .use(createPinia())
      .use(createI18n())
      .mixin({ methods: { route } })
      .mount(el)
    return app
  },
  progress: {
    color: '#4B5563',
  },
})
