import { useStorage } from '@vueuse/core'
import { createI18n as createClientI18n } from 'vue-i18n'

const messages = {
  'en-US': {
    signUp: {
      lookingForJobs: 'Looking for jobs?',
      lookingForStaff: 'Looking for staff?',
    },
    job: {
      search: {
        loading: 'Loading job posts...',
        noResults: 'No results found',
        filter: {
          category: 'Filter by Category',
          skill: 'Filter by Skill',
          availability: 'Filter by Availability',
          expectedSalary: 'Filter by Expected Salary',
          lastSeen: 'Filter by Last Seen',
          apply: 'Apply Filters',
        },
        input: {
          placeholder: 'Keyword Search...',
        },
        item: {
          salaryOffered: 'Salary Offered',
          experience: 'Experience',
          availability: 'Availability',
          skills: 'Skills',
          verified: 'verified',
          moreInfo: 'more info',
        },
      },
    },
    employee: {
      search: {
        loading: 'Loading employees...',
        noResults: 'No results found',
        filter: {
          category: 'Filter by Category',
          skill: 'Filter by Skill',
          availability: 'Filter by Availability',
          expectedSalary: 'Filter by Expected Salary',
          lastSeen: 'Filter by Last Seen',
          apply: 'Apply Filters',
          advanceSearch: 'Advance Search',
          label: {
            skills: 'SKILLS',
            categories: 'SKILLS CATEGORY',
          },
        },
        input: {
          placeholder: 'Keyword Search...',
        },
        item: {
          expectedSalary: 'Expected Salary',
          experience: 'Experience',
          availability: 'Availability',
          skills: 'Skills',
          verified: 'verified',
          view: 'view',
          na: 'N/A',
          markHired: 'Mark Hired',
        },
      },
    },
    dashboard: {
      search: {
        input: {
          placeholder: 'Keyword Search...',
        },
        button: 'Search',
      },
    },
  },
}

export function createI18n() {
  const defaultLocale = useStorage('locale', navigator?.language || 'en')
  return createClientI18n({
    legacy: false,
    locale: defaultLocale.value,
    fallbackLocale: 'en-US',
    messages,
  })
}

export default {}
